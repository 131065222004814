import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import TopLevelView from "./TopLevelView";

export default function ErrorView() {
  const error = useRouteError();
  console.error(error);

  return (
    <TopLevelView>
      <div className="flex flex-col items-center pt-10">
        <p>Sorry, an unexpected error has occurred.</p>
        <i>{isRouteErrorResponse(error) ? error.statusText : ""}</i>
      </div>
    </TopLevelView>
  );
}
