import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { AuthContext } from "@/context/AuthContext";
import { backendUrls } from "@/urls";
import axiosInstance from "@/utils/axios";
import { useQuery } from "@tanstack/react-query";
import { useContext, useMemo } from "react";
import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from "recharts";

type AggregationsResponse = {
  followers: {
    total: number;
  };
  playlists: {
    total: number;
    perCategory: [
      {
        followers: number;
        count: number;
        category: string;
      }
    ];
  };
};

const playlistsPerCategoryConfig = {
  desktop: {
    label: "Playlists",
    color: "--color-chart-1",
  },
} satisfies ChartConfig;

export const Aggregations = () => {
  const { token } = useContext(AuthContext);

  const { data: playlistsPerCategoryResult } = useQuery({
    queryKey: ["playlist-aggregations"],
    queryFn: async () => {
      const result = await axiosInstance.get<AggregationsResponse>(
        backendUrls.getPlaylistAgggregations,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return result;
    },
  });

  const playlistsPerCategoryData = useMemo(
    () =>
      playlistsPerCategoryResult?.data.playlists.perCategory.map((value) => ({
        category: value.category,
        total: value.count,
      })),
    [playlistsPerCategoryResult]
  );

  const followersPerCategoryData = useMemo(
    () =>
      playlistsPerCategoryResult?.data.playlists.perCategory.map((value) => ({
        category: value.category,
        total: value.followers,
      })),
    [playlistsPerCategoryResult]
  );

  return (
    <div className="grid grid-cols-2 grid-rows-1 gap-4">
      <Card>
        <CardHeader>
          <CardTitle>Playlists per Category</CardTitle>
          <CardContent>
            <ChartContainer config={playlistsPerCategoryConfig}>
              <BarChart
                accessibilityLayer
                data={playlistsPerCategoryData}
              >
                <CartesianGrid vertical={false} />
                <XAxis
                  dataKey="category"
                  tickLine={false}
                  tickMargin={5}
                  axisLine={false}
                  angle={45}
                  textAnchor="start"
                  height={90}
                />
                <ChartTooltip
                  cursor={false}
                  content={<ChartTooltipContent indicator="dashed" />}
                />
                <Bar dataKey="total" fill="hsl(var(--chart-1))" radius={4}>
                  <LabelList
                    position="top"
                    offset={-20}
                    className="fill-foreground"
                    fontSize={12}
                  />
                </Bar>
              </BarChart>
            </ChartContainer>
          </CardContent>
        </CardHeader>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Followers per Category</CardTitle>
          <CardContent>
            <ChartContainer config={playlistsPerCategoryConfig}>
              <BarChart
                accessibilityLayer
                data={followersPerCategoryData}
              >
                <CartesianGrid vertical={false} />
                <XAxis
                  dataKey="category"
                  tickLine={false}
                  tickMargin={5}
                  axisLine={false}
                  angle={45}
                  textAnchor="start"
                  height={90}
                />
                <ChartTooltip
                  cursor={false}
                  content={<ChartTooltipContent indicator="dashed" />}
                />
                <Bar dataKey="total" fill="hsl(var(--chart-1))" radius={4}>
                  <LabelList
                    position="top"
                    offset={-20}
                    className="fill-foreground"
                    fontSize={12}
                  />
                </Bar>
              </BarChart>
            </ChartContainer>
          </CardContent>
        </CardHeader>
      </Card>
    </div>
  );
};
