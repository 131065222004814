import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Progress } from "@/components/ui/progress";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { AuthContext } from "@/context/AuthContext";
import { SpotifyAuthContext } from "@/context/SpotifyAuthContext";
import { backendUrls } from "@/urls";
import axiosInstance from "@/utils/axios";
import { StatusResponse } from "@/views/Actions";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useCallback, useContext, useState } from "react";
dayjs.extend(relativeTime);

type GeneratePlaylistMutationParams = {
  mood: string;
  genre: string;
  count: number;
  seedTrack: string;
};

type PlaylistGeneratorProps = {
  status?: StatusResponse;
};
export default function PlaylistGenerator(params: PlaylistGeneratorProps) {
  const [mood, setMood] = useState<string>("");
  const [customMood, setCustomMood] = useState<string>("");
  const [genre, setGenre] = useState<string>("");
  const [customGenre, setCustomGenre] = useState<string>("");
  const [count, setCount] = useState<number>(1);
  const [seedTrack, setSeedTrack] = useState<string>("");
  const { token } = useContext(AuthContext);
  const {
    token: spotifyToken,
    clientId: spotifyClientId,
    clientSecret: spotifyClientSecret,
  } = useContext(SpotifyAuthContext);

  const generatePlaylists = useCallback(
    async (params: GeneratePlaylistMutationParams) => {
      const result = await axiosInstance.post(
        backendUrls.generatePlaylists,
        { ...params },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "spotify-token": JSON.stringify(spotifyToken),
            "spotify-client-id": JSON.stringify(spotifyClientId),
            "spotify-client-secret": JSON.stringify(spotifyClientSecret),
          },
        }
      );
      return result;
    },
    []
  );

  const mutation = useMutation({
    mutationFn: generatePlaylists,
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutation.mutate({
      mood: mood === "custom" ? customMood : mood,
      genre: genre === "custom" ? customGenre : genre,
      count,
      seedTrack,
    });
  };

  return (
    <Card className="w-full mx-auto">
      <CardHeader>
        <CardTitle>Generate New Release Playlists</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="mood">Mood</Label>
            <Select value={mood} onValueChange={setMood}>
              <SelectTrigger id="mood">
                <SelectValue placeholder="Select mood" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Gute Laune">Gute Laune</SelectItem>
                <SelectItem value="Sad">Sad</SelectItem>
                <SelectItem value="Workout">Workout</SelectItem>
                <SelectItem value="custom">Custom</SelectItem>
              </SelectContent>
            </Select>
            {mood === "custom" && (
              <Input
                placeholder="Enter custom mood"
                value={customMood}
                onChange={(e) => setCustomMood(e.target.value)}
              />
            )}
          </div>

          <div className="space-y-2">
            <Label htmlFor="genre">Genre</Label>
            <Select value={genre} onValueChange={setGenre}>
              <SelectTrigger id="genre">
                <SelectValue placeholder="Select genre" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Pop Deutsch">Pop Deutsch</SelectItem>
                <SelectItem value="Pop English">Pop English</SelectItem>
                <SelectItem value="Indie">Indie</SelectItem>
                <SelectItem value="Singer Songwriter">
                  Singer Songwriter
                </SelectItem>
                <SelectItem value="Rock">Rock</SelectItem>
                <SelectItem value="Country">Country</SelectItem>
                <SelectItem value="custom">Custom</SelectItem>
              </SelectContent>
            </Select>
            {genre === "custom" && (
              <Input
                placeholder="Enter custom genre"
                value={customGenre}
                onChange={(e) => setCustomGenre(e.target.value)}
              />
            )}
          </div>

          <div className="space-y-2">
            <Label htmlFor="count">Number of Playlists</Label>
            <Input
              id="count"
              type="number"
              min="1"
              value={count}
              onChange={(e) => setCount(parseInt(e.target.value))}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="seedTrack">Spotify Seed Track</Label>
            <Input
              id="seedTrack"
              placeholder="Enter Spotify track URL or ID"
              value={seedTrack}
              onChange={(e) => setSeedTrack(e.target.value)}
            />
          </div>

          <Button type="submit" className="w-full" disabled={mutation.isPaused}>
            {mutation.isPending ? "Generating..." : "Generate Playlists"}
          </Button>

          {params.status?.status && params.status.status !== "done" && (
            <div className="space-y-2">
              <Label>Progress</Label>
              <Progress value={params.status?.progress} className="w-full"/>
            </div>
          )}
        </form>
      </CardContent>
      <CardFooter>
        {params.status?.status !== "none" ? (
          <div className="grid auto-rows-min">
            <div className="p-2">
              <span className="text-sm font-normal text-muted-foreground">
                Started {dayjs(params.status?.started).fromNow()} and current
                status is {params.status?.status}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </CardFooter>
    </Card>
  );
}
