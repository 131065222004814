import { Loader2 } from "lucide-react";
import { Button, ButtonProps } from "./ui/button";

export default function LoadingButton(props: ButtonProps) {
  return (
    <Button {...props} disabled>
      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
      {props.title}
    </Button>
  );
}
