import NewReleaseGenerator from "@/components/NewReleaseGenerator";
import PlaylistGenerator from "@/components/PlaylistGenerator";
import UpdateStatisticsCard from "@/components/UpdateStatisticsCard";
import { AuthContext } from "@/context/AuthContext";
import { backendUrls } from "@/urls";
import axiosInstance from "@/utils/axios";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

export type StatusResponse = {
  status: string;
  progress: number;
  started: string;
  error?: string;
};

type StatusResponseDict = {
  [key: string]: StatusResponse;
};
export const Actions = () => {
  const { token } = useContext(AuthContext);

  const { data } = useQuery({
    queryKey: ["updateStatisticsStatus"],
    queryFn: async () => {
      const result = await axiosInstance.get<StatusResponseDict>(
        backendUrls.tasksStatus,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return result;
    },
    refetchInterval: 2000,
  });
  return (
    <div className="w-full flex flex-wrap gap-2">
      <div className="flex-grow">
      <PlaylistGenerator status={data?.data?.["generate-playlists"]} />
      </div>
      <div className="flex flex-grow flex-col gap-2">
        <NewReleaseGenerator
          status={data?.data?.["generate-new-release-playlists"]}
        />
        <UpdateStatisticsCard status={data?.data?.["update-statistics"]} />
      </div>
    </div>
  );
};
