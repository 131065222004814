import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { RoundSpinner } from "@/components/ui/spinner";
import { useMutation } from "@tanstack/react-query";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SpotifyAuthContext } from "../context/SpotifyAuthContext";
import { frontendUrls } from "../urls";
import axiosInstance from "../utils/axios";

export default function SpotifyAuthView() {
  const {
    token,
    setToken,
    clientId,
    clientSecret,
  } = useContext(SpotifyAuthContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [oauth2State, setOauth2State] = useLocalStorage<string>(
    "oauth2State",
    undefined
  );
  const requestAccesstokenMutation = useMutation({
    onSuccess: (data) => {
      setToken(data.data);
      navigate(frontendUrls.home);
    },
    mutationFn: async (variables: { code: string }) => {
      const params = new URLSearchParams();
      params.append("show_dialog", "true");
      params.append("code", variables.code);
      params.append(
        "redirect_uri",
        import.meta.env.VITE_BASE_URL + "/spotify/auth"
      );
      params.append("grant_type", "authorization_code");
      const result = await axiosInstance.post(
        "https://accounts.spotify.com/api/token",
        params,
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Basic " + btoa(clientId + ":" + clientSecret),
          },
        }
      );

      return result;
    },
  });
  useEffect(() => {
    const code = searchParams.get("code");
    if (!code) {
      return;
    } else if (!token && requestAccesstokenMutation.isIdle) {
      var state = searchParams.get("state");
      console.log(state, oauth2State, requestAccesstokenMutation.status);

      if (!state || state !== oauth2State) {
        throw Error("State mismatch");
      } else {
        requestAccesstokenMutation.mutate({ code });
      }
    }
  }, [searchParams, requestAccesstokenMutation, token]);

  const requestAuthCode = () => {
    setToken(undefined);
    const state = window.crypto.randomUUID();
    setOauth2State(state);
    const url =
      "https://accounts.spotify.com/authorize?" +
      new URLSearchParams({
        response_type: "code",
        client_id: clientId || "",
        redirect_uri: import.meta.env.VITE_BASE_URL + "/spotify/auth",
        scope:
          "user-read-email user-read-private playlist-modify-public ugc-image-upload",
        state: state,
        show_dialog: "true"
      }).toString();

    window.location.replace(url);
  };

  useEffect(() => {
    const code = searchParams.get("code");
    if (!code) {
      requestAuthCode();
    }
  }, [searchParams]);

  return (
    <div>
      <Card className="w-full max-w-md mx-auto">
        <CardHeader>
          <CardTitle>Spotify Authentication</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex justify-center items-center gap-2">
            <RoundSpinner size="xs" />
            Logging into Spotify
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
