import Logo from "@/assets/spotify-playlist-gen-favicon.svg?react";
import { SupabaseContext } from "@/context/SupabaseContext";
import { LayoutDashboard, LogOut, PlayCircle } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { frontendUrls } from "../urls";
import MenuLink from "./MenuLink";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "./ui/navigation-menu";

export default function TopMenu() {
  const {supabase} = useContext(SupabaseContext)
  const [ email, setEmail] = useState("");

  useEffect(() => {
    const getEmail = async() => {
      setEmail((await supabase.auth.getUser()).data.user?.email || "")
    }
    getEmail();
  }, [])
  return (
    <div className="flex items-center justify-between pb-8 pt-2 text-sec">
      <NavigationMenu>
        <NavigationMenuList className="flex gap-4">
          <NavigationMenuItem>
            <a href="/">
              <Logo className="w-8 h-8" />
            </a>
          </NavigationMenuItem>

          <NavigationMenuItem>
            <MenuLink
              to={frontendUrls.home}
              icon={<LayoutDashboard size={18} />}
            >
              Dashboard
            </MenuLink>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <MenuLink to={frontendUrls.actions} icon={<PlayCircle size={18} />}>
              Actions
            </MenuLink>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
      <NavigationMenu>
        <NavigationMenuList className="flex gap-4">
          <div className="text-sm italic text-muted-foreground">
            {email}
          </div>
          <NavigationMenuItem>
            <MenuLink to={frontendUrls.logout} icon={<LogOut size={18} />}>
              Logout
            </MenuLink>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    </div>
  );
}
