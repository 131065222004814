import { Session } from "@supabase/supabase-js";
import { useLocalStorage } from "@uidotdev/usehooks";
import React, {
  PropsWithChildren,
  useMemo
} from "react";

interface IAuthContext {
  session?: Session;
  token?: string;
  setSession: React.Dispatch<React.SetStateAction<Session | undefined>>;
}

const defaultContext: IAuthContext = {
  session: undefined,
  token: undefined,
  setSession: () => {}
};
export const AuthContext = React.createContext<IAuthContext>(defaultContext);

export default function AuthContextProvider({ children }: PropsWithChildren) {
  const [session, setSession] = useLocalStorage<Session|undefined>("sb-session", undefined);
  const token = useMemo(
    () =>
      btoa(
        JSON.stringify({
          access_token: session?.access_token,
          refresh_token: session?.refresh_token,
        })
      ),
    [session]
  );
  
  return (
    <AuthContext.Provider value={{ session, token, setSession }}>
      {children}
    </AuthContext.Provider>
  );
}
