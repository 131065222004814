import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { AuthContext } from "@/context/AuthContext";
import { SpotifyAuthContext } from "@/context/SpotifyAuthContext";
import { backendUrls } from "@/urls";
import axiosInstance from "@/utils/axios";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { StatusResponse } from "@/views/Actions";
import { useCallback, useContext } from "react";
import LoadingButton from "./LoadingButton";
import { Button } from "./ui/button";
dayjs.extend(relativeTime);

type UpdateStatisticsProps = {
  status?: StatusResponse;
};

export default function UpdateStatisticsCard({
  status,
}: UpdateStatisticsProps) {
  const { token } = useContext(AuthContext);
  const {
    token: spotifyToken,
    clientId: spotifyClientId,
    clientSecret: spotifyClientSecret,
  } = useContext(SpotifyAuthContext);

  const { isPending, mutate: mutateUpdateStatistics } = useMutation({
    mutationFn: async () => {
      const result = await axiosInstance.post(
        backendUrls.updateStatistics,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "spotify-token": JSON.stringify(spotifyToken),
            "spotify-client-id": JSON.stringify(spotifyClientId),
            "spotify-client-secret": JSON.stringify(spotifyClientSecret),
          },
        }
      );

      return result;
    },
  });

  const handleUpdateStatistics = useCallback(() => {
    mutateUpdateStatistics();
  }, []);

  return (
    <Card className="w-full mx-auto">
      <CardHeader>
        <CardTitle>Update Statistics</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="p-2 pb-4">
          {isPending || status?.status == "started" ? (
            <LoadingButton title="Processing" className="w-full" />
          ) : (
            <Button className="w-full" onClick={handleUpdateStatistics}>
              Start
            </Button>
          )}
        </div>
        {status?.status && status?.status !== "none" ? (
          <div className="grid auto-rows-min gap-2">
            {status?.error ? (
              <div className="p-2">Error: {status?.error}</div>
            ) : (
              <></>
            )}
            <div className="p-2 w-full">
              <Progress value={Math.round(status?.progress || 0)} />
            </div>
          </div>
        ) : (
          <></>
        )}
      </CardContent>
      <CardFooter>
        {status?.status && status?.status !== "none" ? (
          <div className="grid auto-rows-min">
            <div className="p-2">
              <span className="text-sm font-normal text-muted-foreground">
                Started {dayjs(status?.started).fromNow()} and current status is{" "}
                {status?.status}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </CardFooter>
    </Card>
  );
}
