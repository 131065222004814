import { PlaylistsTable } from "@/components/PlaylistsTable";
import { Aggregations } from "./Aggregations";

export const Playlists = () => {
 

  return (
    <div className="grid auto-rows-min gap-4">
      <PlaylistsTable/>
      <Aggregations/>
    </div>
  );
};
