import { useLocalStorage } from "@uidotdev/usehooks";
import React, { PropsWithChildren, useEffect } from "react";

interface ISpotifyAuthContext {
  token?: string;
  setToken: React.Dispatch<React.SetStateAction<undefined>>;
  clientId?: string;
  setClientId: React.Dispatch<React.SetStateAction<string>>;
  clientSecret?: string;
  setClientSecret: React.Dispatch<React.SetStateAction<string>>;
}

const defaultContext: ISpotifyAuthContext = {
  token: undefined,
  setToken: () => {},
  clientId: undefined,
  setClientId: () => {},
  clientSecret: undefined,
  setClientSecret: () => {},
};

export const SpotifyAuthContext =
  React.createContext<ISpotifyAuthContext>(defaultContext);

export default function SpotifyAuthContextProvider({
  children,
}: PropsWithChildren) {
  const [token, setToken] = useLocalStorage("spotify-token", undefined);
  const [clientId, setClientId] = useLocalStorage("spotifyClientId", "");
  const [clientSecret, setClientSecret] = useLocalStorage(
    "spotifyClientSecret",
    ""
  );
  useEffect(() => {
    setClientId(import.meta.env.VITE_SPOTIFY_CLIENT_KEY);
    setClientSecret(import.meta.env.VITE_SPOTIFY_CLIENT_SECRET);
  }, []);
  return (
    <SpotifyAuthContext.Provider
      value={{
        token,
        setToken,
        clientId,
        setClientId,
        clientSecret,
        setClientSecret,
      }}
    >
      {children}
    </SpotifyAuthContext.Provider>
  );
}
