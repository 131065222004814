export const frontendUrls = {
  home: "/",
  playlists: "/playlists",
  actions: "/actions",
  login: "/login",
  logout: "/logout",
  settings: "/settings",
  spotifyAuth: "/spotify/auth",
};

export const backendUrls = {
  updateStatistics: import.meta.env.VITE_PLAYLIST_GENERATOR_URL + "/statistics/update",
  generatePlaylists: import.meta.env.VITE_PLAYLIST_GENERATOR_URL + "/playlists/generate",
  generateNewReleasePlaylists: import.meta.env.VITE_PLAYLIST_GENERATOR_URL + "/playlists/new-releases/generate",
  getPlaylistAgggregations: import.meta.env.VITE_PLAYLIST_GENERATOR_URL + "/statistics/playlists",
  tasksStatus: import.meta.env.VITE_PLAYLIST_GENERATOR_URL + "/tasks",
  deletePlaylist: import.meta.env.VITE_PLAYLIST_GENERATOR_URL + "/playlists/",
};