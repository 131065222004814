import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { SupabaseContext } from "../context/SupabaseContext";
import { frontendUrls } from "../urls";

export default function LoginView() {
  const { supabase } = useContext(SupabaseContext);
  const { setSession } = useContext(AuthContext);
  const navigate = useNavigate();
  const { isLoading, data: sessionData } = useQuery({
    queryKey: ["session"],
    queryFn: () => {
      const result = supabase.auth.getSession();
      return result;
    },
  });

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session !== null) {
        setSession(session);
        navigate(frontendUrls.home);
      } else {
        setSession(undefined);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (sessionData && sessionData.data.session !== null) {
      setSession(sessionData.data.session);
      navigate(frontendUrls.home);
    } else {
      setSession(undefined);
    }
  }, [sessionData]);

  if (isLoading) {
    return <>Loading</>;
  }

  return (
    <div>
      <Card className="w-full max-w-md mx-auto">
        <CardHeader>
          <CardTitle>Authentication</CardTitle>
        </CardHeader>
        <CardContent>
          <Auth
            showLinks={false}
            supabaseClient={supabase}
            appearance={{ theme: ThemeSupa }}
            providers={[]}
          />
        </CardContent>
      </Card>
    </div>
  );
}
