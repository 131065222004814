import { Link, useLocation } from "react-router-dom";
import { NavigationMenuLink, navigationMenuTriggerStyle } from "./ui/navigation-menu";

interface MenuLinkProps {
  to: string
  children: React.ReactNode
  icon: React.ReactNode
}

export default function MenuLink ({ to, icon, ...props }: MenuLinkProps) {
    const location = useLocation();
    const isActive = to === location.pathname;
  
    return (
      <NavigationMenuLink asChild active={isActive} className={navigationMenuTriggerStyle()}>
        <Link to={to} className="NavigationMenuLink flex gap-2" {...props}>{icon}{props.children}</Link>
      </NavigationMenuLink>
    );
  };