import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from "react-router-dom";
import { frontendUrls } from "./urls";
import { Actions } from "./views/Actions";
import AuthenticatedView from "./views/AuthenticatedView";
import ErrorView from "./views/ErrorView";
import LoginView from "./views/LoginView";
import { Logout } from "./views/Logout";
import { Playlists } from "./views/Playlists";
import SpotifyAuthView from "./views/SpotifyAuthView";
import TopLevelView from "./views/TopLevelView";

const Router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        element={
          <TopLevelView>
            <Outlet />
          </TopLevelView>
        }
        errorElement={<ErrorView />}
      >
        <Route>
          <Route path={frontendUrls.login} element={<LoginView />} />
          <Route path={frontendUrls.spotifyAuth} element={<SpotifyAuthView />} />

          <Route path={frontendUrls.home} element={<AuthenticatedView />}>
            <Route index element={<Playlists />} />
            <Route path={frontendUrls.actions} element={<Actions />} />
            <Route path={frontendUrls.logout} element={<Logout />} />
          </Route>
        </Route>
      </Route>
    </>
  )
);

export default Router;
