import { AuthContext } from "@/context/AuthContext";
import { SpotifyAuthContext } from "@/context/SpotifyAuthContext";
import { SupabaseContext } from "@/context/SupabaseContext";
import { useContext, useEffect } from "react";

export const Logout = () => {
  const { setToken } = useContext(SpotifyAuthContext);
  const { setSession } = useContext(AuthContext);
  const { supabase } = useContext(SupabaseContext);
  
  useEffect(() => {
    supabase.auth.signOut();
    setSession(undefined);
    setToken(undefined);
  }, [])

  return (
    <div className="w-full flex justify-center">
      Logging out...
    </div>
  );
};
