import { DataTable } from "@/components/DataTable";
import { Button } from "@/components/ui/button";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { AuthContext } from "@/context/AuthContext";
import { SpotifyAuthContext } from "@/context/SpotifyAuthContext";
import { useToast } from "@/hooks/use-toast";
import { backendUrls } from "@/urls";
import axiosInstance from "@/utils/axios";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ColumnDef,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { ArrowUpDown, Trash2 } from "lucide-react";
import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { Playlist, PlaylistDataContext } from "../context/PlaylistDataContext";

type ColumnDefParameters = {
  deletePlaylistFunc: (playlist_id: string) => Promise<void>;
  disabledIds: string[];
  setDisabledIds: Dispatch<SetStateAction<string[]>>;
};
export const getColumnDefs: (
  params: ColumnDefParameters
) => ColumnDef<Playlist>[] = (params: ColumnDefParameters) => [
  {
    accessorKey: "id",
    id: "id",
    cell: ({ row }) => <span className="tabular-nums">{row.original.id}</span>,
    header: ({ column }) => {
      return (
        <Button
          size="text"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          ID
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: "playlist_meta_data_id.name",
    header: "Name",
    cell: ({ row }) => {
      return (
        <Button
          variant="link"
          size="text"
          className="text-ellipsis overflow-hidden w-full whitespace-nowrap"
        >
          <a
            href={row.original.playlist_meta_data_id.spotify_url}
            className="text-ellipsis overflow-hidden w-full whitespace-nowrap"
          >
            {row.original.playlist_meta_data_id.name}
          </a>
        </Button>
      );
    },
  },
  {
    id: "playlist_meta_data_id.followers",
    accessorKey: "playlist_meta_data_id.followers",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="text"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Followers
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: "playlist_meta_data_id.images",
    header: "Image",
    cell: ({ row }) => {
      try {
        const images = JSON.parse(row.original.playlist_meta_data_id.images);
        return <img src={images[0].url} className="h-10 w-10" />;
      } catch {
        return <></>;
      }
    },
  },
  {
    accessorKey: "playlist_meta_data_id.owner_display_name",
    header: "Owner",
    cell: ({ row }) => {
      return (
        <Button
          variant="link"
          size="text"
          className="text-ellipsis overflow-hidden w-full whitespace-nowrap"
        >
          <a
            className="text-ellipsis overflow-hidden w-full whitespace-nowrap"
            href={
              "https://open.spotify.com/user/" +
              row.original.playlist_meta_data_id.owner_id
            }
          >
            {row.original.playlist_meta_data_id.owner_display_name}
          </a>
        </Button>
      );
    },
  },
  {
    accessorKey: "created_at",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="text"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Created At
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: "mood",
    header: "Mood",
  },
  {
    accessorKey: "genre",
    header: "Genre",
  },
  {
    accessorKey: "track_count",
    header: "Tracks",
  },
  {
    accessorKey: "actions",
    header: () => {
      return <div className="flex justify-end pr-4">Actions</div>;
    },
    cell: ({ row }) => {
      return (
        <div className="flex w-full justify-end pr-4">
          <Button
            variant="ghost"
            size="text"
            disabled={params.disabledIds.includes(row.original.id)}
            className="text-ellipsis overflow-hidden whitespace-nowrap"
            onClick={async () => {
              params.setDisabledIds((ids) => [
                ...new Set([...ids, row.original.id]),
              ]);
              await params.deletePlaylistFunc(row.original.id);
              params.setDisabledIds((ids) => {
                const set = new Set(ids);
                set.delete(row.original.id);
                return [...set];
              });
            }}
          >
            <Trash2 height={16} className="stroke-red-500" />
          </Button>
        </div>
      );
    },
  },
];

export const PlaylistsTable = () => {
  const { getPlaylists } = useContext(PlaylistDataContext);
  const [disabledIds, setDisabledIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "playlist_meta_data_id.followers",
      desc: true,
    },
  ]);
  const queryClient = useQueryClient()

  const { data, isPending  } = useQuery({
    queryKey: ["playlists", pagination, sorting],
    queryFn: async () => {
      return await getPlaylists({
        page: pagination?.pageIndex,
        limit: pagination?.pageSize,
        sorting,
      });
    },
    placeholderData: keepPreviousData,
  });

  const defaultData = useMemo(() => [], []);
  const { token } = useContext(AuthContext);
  const { toast } = useToast();

  const {
    token: spotifyToken,
    clientId: spotifyClientId,
    clientSecret: spotifyClientSecret,
  } = useContext(SpotifyAuthContext);
  const deletePlaylistMutation = useMutation({
    mutationFn: async (id: string) => {
      try {
        await axiosInstance.delete(backendUrls.deletePlaylist + id, {
          headers: {
            Authorization: `Bearer ${token}`,
            "spotify-token": JSON.stringify(spotifyToken),
            "spotify-client-id": JSON.stringify(spotifyClientId),
            "spotify-client-secret": JSON.stringify(spotifyClientSecret),
          },
        });
      } catch (error: any) {
        console.log(error);
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description:
            error?.response?.data?.detail || error?.message || "Unknown error",
        });
      }
      queryClient.invalidateQueries({ queryKey: ['playlists'] })

    },
  });

  const columns = useMemo(() => {
    return getColumnDefs({
      deletePlaylistFunc: deletePlaylistMutation.mutateAsync,
      disabledIds,
      setDisabledIds,
    });
  }, [deletePlaylistMutation, disabledIds, setDisabledIds]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Evergreen Playlists</CardTitle>
        <CardDescription>
          {data?.total ? `${data?.total} Playlists` : ""}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <DataTable
          columns={columns}
          data={data?.playlists || defaultData}
          rowCount={data?.total || 0}
          pagination={pagination}
          onPaginationChange={setPagination}
          sorting={sorting}
          onSortingChange={setSorting}
          isLoading={isPending}
        />
      </CardContent>
    </Card>
  );
};
