import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { createContext, PropsWithChildren } from "react";

interface SupabaseContextType {
  supabase: SupabaseClient;
}

const client = createClient(import.meta.env.VITE_SUPABASE_URL, import.meta.env.VITE_SUPABASE_KEY)

export const SupabaseContext = createContext<SupabaseContextType>({
  supabase: client,
});

export const SupabaseContextProvider = ({ children }: PropsWithChildren) => {
  return <SupabaseContext.Provider value={{ supabase: client }}>{children}</SupabaseContext.Provider>;
};
