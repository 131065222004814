import React, { PropsWithChildren, useCallback, useContext } from "react";
import { SupabaseContext } from "./SupabaseContext";

export interface Playlist {
  id: string;
  created_at: string;
  mood: string;
  genre: string;
  track_count: number;
  recommendation_strategy: string;
  playlist_meta_data_id: {
    description: string;
    followers: number;
    images: string;
    name: string;
    owner_display_name: string;
    owner_id: string;
    owner_uri: string;
    spotify_url: string;
  };
}

interface GetPlaylistData {
  total: number;
  playlists: Playlist[];
}

interface GetPlaylistDataParams {
  page?: number;
  limit?: number;
  sorting?: {
    desc: boolean;
    id: string;
  }[];
}

interface IPlaylistDataContext {
  getPlaylists: (params: GetPlaylistDataParams) => Promise<GetPlaylistData>;
}

const defaultContext = {
  getPlaylists: async () => ({
    total: 0,
    playlists: [],
  }),
};

export const PlaylistDataContext =
  React.createContext<IPlaylistDataContext>(defaultContext);

export const PlaylistDataContextProvider = ({
  children,
}: PropsWithChildren) => {
  const { supabase } = useContext(SupabaseContext);

  const getPlaylists = useCallback(
    async (params: GetPlaylistDataParams) => {
      const limit = params.limit || 10;
      const page = params.page || 0;
      const from = page * limit;

      let query = supabase
        .from("evergreen-playlists")
        .select("*,playlist_meta_data_id(*)")
        .not("playlist_meta_data_id", "is", null)
        .range(from, from + limit - 1);

      if (params.sorting) {
        for (const field of params.sorting) {
          const fullId = field.id.split(".").reverse();
          let fieldName = fullId[0];
          const referencedTable = fullId[1] || undefined;

          if (referencedTable != undefined) {
            fieldName = `${referencedTable}(${fieldName})`;
          }
          console.log(fieldName, { ascending: !field.desc });
          query = query.order(fieldName, { ascending: !field.desc });
        }
      }

      const { data, error } = await query;

      if (error) {
        throw error;
      }
      console.log(data);

      const { count, error: countError } = await supabase
        .from("evergreen-playlists")
        .select("*", { count: "exact", head: true });
      if (countError) {
        throw countError;
      }
      return { total: count || 0, playlists: data };
    },
    [supabase]
  );

  return (
    <PlaylistDataContext.Provider value={{ getPlaylists }}>
      {children}
    </PlaylistDataContext.Provider>
  );
};
