import { useContext, useEffect } from "react";
import { useNavigate, useOutlet } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { SpotifyAuthContext } from "../context/SpotifyAuthContext";
import { frontendUrls } from "../urls";

export default function AuthenticatedView() {
  const { session } = useContext(AuthContext);
  const { token } = useContext(SpotifyAuthContext);
  const outlet = useOutlet();

  const navigate = useNavigate();
  useEffect(() => {
    if (!session) {
      navigate(frontendUrls.login);
      return
    }

    if (!token) {
        navigate(frontendUrls.spotifyAuth)
        return
    }
  }, [session, navigate]);

  return <>{outlet}</>;
}
