
import axios from 'axios';
import { frontendUrls } from '../urls';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
});

/**
 * Catch the AunAuthorized Request
 */
axiosInstance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    window.location.replace(frontendUrls.login);
  }

  return Promise.reject(error);
});

export default axiosInstance;