import { Toaster } from "@/components/ui/toaster";
import { PrimeReactProvider } from "primereact/api";
import { PropsWithChildren } from "react";
import TopMenu from "../components/TopMenu";
import AuthContextProvider from "../context/AuthContext";
import { PlaylistDataContextProvider } from "../context/PlaylistDataContext";
import SpotifyAuthContextProvider from "../context/SpotifyAuthContext";
import { SupabaseContextProvider } from "../context/SupabaseContext";

export default function TopLevelView({ children }: PropsWithChildren) {
  return (
    <PrimeReactProvider>
      <SupabaseContextProvider>
        <AuthContextProvider>
          <SpotifyAuthContextProvider>
            <TopMenu />
            <PlaylistDataContextProvider>
              {children}
            </PlaylistDataContextProvider>
          </SpotifyAuthContextProvider>
        </AuthContextProvider>
      </SupabaseContextProvider>
      <Toaster />
    </PrimeReactProvider>
  );
}
